import React from 'react'
import { Routes as RRDoutes, Route } from 'react-router-dom'

import Home from '../pages/Home'
import AuthLayout from './AuthLayout'

// Public pages
import SignIn from '../pages/Auth/SignIn'
import Recover from '../pages/Auth/Recover'

import ProdigyHome from '../public/Home'
import About from '../public/About'
import Contact from '../public/Contact'
import Products from '../public/Products'
import Product from '../public/Product'
import Posts from '../public/Posts'
import Post from '../public/Post'

import Services from '../public/Stages'
import Service from '../public/Stage'

import Dealers from '../public/Dealers'
import DealersBrasil from '../public/Dealers/brasil'
import DealersOthers from '../public/Dealers/others'

import UnknownPage from '../pages/UnknownPage'
import Privacy from '../pages/Privacy'
// Public pages

// Private pages
import LogsForm from '../pages/Logs/Form'
import LogsIndex from '../pages/Logs/Index'
import LogsView from '../pages/Logs/View'

import PasswordChange from '../pages/Profile/PasswordChange'

import PerformanceIndex from '../pages/Performance/Index'
import PerformanceView from '../pages/Performance/View'
import PerformanceForm from '../pages/Performance/Form'

import RequestIndex from '../pages/Request/Index'
import RequestAll from '../pages/Request/Index/all'
import RequestView from '../pages/Request/View'
import RequestForm from '../pages/Request/Form'
import RequestChat from '../pages/Request/Chat'

import BillIndex from '../pages/Bill/Index'
import BillAll from '../pages/Bill/Index/all'
import BillView from '../pages/Bill/View'

import ManufacturerIndex from '../pages/Manufacturer/Index'
import ManufacturerView from '../pages/Manufacturer/View'
import ManufacturerForm from '../pages/Manufacturer/Form'

import ModelsIndex from '../pages/Models/Index'
import ModelsView from '../pages/Models/View'
import ModelsForm from '../pages/Models/Form'

import YearsIndex from '../pages/Years/Index'
import YearsView from '../pages/Years/View'
import YearsForm from '../pages/Years/Form'

import MotorsIndex from '../pages/Motors/Index'
import MotorsView from '../pages/Motors/View'
import MotorsForm from '../pages/Motors/Form'

import StagesIndex from '../pages/Stages/Index'
import StagesView from '../pages/Stages/View'
import AdditionalStagesView from '../pages/Stages/View/add'
import StagesForm from '../pages/Stages/Form'

import StagesTypesIndex from '../pages/StagesTypes/Index'
import StagesTypesView from '../pages/StagesTypes/View'
import StagesTypesForm from '../pages/StagesTypes/Form'

import AdditionalsIndex from '../pages/Additionals/Index'
import AdditionalsView from '../pages/Additionals/View'
import AdditionalsForm from '../pages/Additionals/Form'

import PostsIndex from '../pages/Posts/Index'
import PostsView from '../pages/Posts/View'
import PostsForm from '../pages/Posts/Form'

import RepresentativesIndex from '../pages/Representatives/Index'
import RepresentativesView from '../pages/Representatives/View'
import RepresentativesForm from '../pages/Representatives/Form'

import DealersIndex from '../pages/Dealers/Index'
import DealersView from '../pages/Dealers/View'
import DealersForm from '../pages/Dealers/Form'

import DealersUsersIndex from '../pages/Dealers/Users/Index'
import DealersUsersView from '../pages/Dealers/Users/View'
import DealersUsersForm from '../pages/Dealers/Users/Form'

import DiscountsIndex from '../pages/Discounts/Index'
import DiscountsForm from '../pages/Discounts/Form'

import DownloadsIndex from '../pages/Downloads/Index'
import DownloadsView from '../pages/Downloads/View'
import DownloadsForm from '../pages/Downloads/Form'

import DownloadsCategoryIndex from '../pages/DownloadsCategory/Index'
import DownloadsCategoryView from '../pages/DownloadsCategory/View'
import DownloadsCategoryForm from '../pages/DownloadsCategory/Form'

import ConfigurationsView from '../pages/Configurations/View'
import ConfigurationsForm from '../pages/Configurations/Form'

import FuelsIndex from '../pages/Fuels/Index'
import FuelsView from '../pages/Fuels/View'
import FuelsForm from '../pages/Fuels/Form'

import ReadingsIndex from '../pages/Readings/Index'
import ReadingsView from '../pages/Readings/View'
import ReadingsForm from '../pages/Readings/Form'

import AdministratorsIndex from '../pages/Administrators/Index'
import AdministratorsView from '../pages/Administrators/View'
import AdministratorsForm from '../pages/Administrators/Form'
// Private pages

const Routes = () => (
  <RRDoutes>
    <Route element={<AuthLayout isPrivate={false} sideBar={false} login />}>
      <Route path='/login' exact element={<SignIn />} />
    </Route>

    <Route element={<AuthLayout isPrivate={false} />}>
      <Route path='/politica-de-privacidade' exact element={<Privacy />} />
      <Route path='/ajuda-com-login' exact element={<Recover />} />

      <Route path='/' exact element={<ProdigyHome />} />
      <Route path='/about' exact element={<About />} />
      <Route path='/contact' exact element={<Contact />} />
      <Route path='/products' exact element={<Products />} />
      <Route path='/product/:_id' exact element={<Product />} />
      <Route path='/dealers' exact element={<Dealers />} />

      <Route path='/dealers/brasil' exact element={<DealersBrasil />} />
      <Route path='/dealers/mexico' exact element={<DealersOthers />} />
      <Route path='/dealers/paraguai' exact element={<DealersOthers />} />
      <Route path='/dealers/colombia' exact element={<DealersOthers />} />

      <Route path='/stage-services' exact element={<Services />} />
      <Route path='/stage-services/:_id' exact element={<Service />} />

      <Route path='/news' exact element={<Posts />} />
      <Route path='/news/:_id' exact element={<Post />} />

      <Route element={<UnknownPage />} />
    </Route>

    <Route element={<AuthLayout isPrivate />}>
      <Route path='/inicio' exact element={<Home />} />

      <Route path='/logs' exact element={<LogsIndex />} />
      <Route path='/log/novo' exact element={<LogsForm />} />
      <Route path='/log/:requestProtocol/novo' exact element={<LogsForm />} />
      <Route path='/log/:_id/update' exact element={<LogsForm />} />
      <Route path='/log/:_id' exact element={<LogsView />} />

      <Route path='/trocar/senha/' exact element={<PasswordChange />} />

      <Route path='/performances' exact element={<PerformanceIndex />} />
      <Route path='/performance/:slug' exact element={<PerformanceView />} />
      <Route
        path='/performance/:slug/editar'
        exact
        element={<PerformanceForm />}
      />

      <Route
        path='/performance/fabricante/nova'
        exact
        element={<PerformanceIndex />}
      />
      <Route
        path='/performance/modelo/novo'
        exact
        element={<PerformanceIndex />}
      />
      {/* modelo, ano, origen */}
      <Route
        path='/performance/versão/nova'
        exact
        element={<PerformanceIndex />}
      />
      <Route
        path='/performance/stage/nova'
        exact
        element={<PerformanceIndex />}
      />

      {/* <Route path='/portal' exact element={<Home />} />
      <Route path='/marketing' exact element={<Home />} />
      <Route path='/usuarios' exact element={<Home />} /> */}

      {/* Performance */}
      <Route path='/fabricantes' exact element={<ManufacturerIndex />} />
      <Route path='/fabricante/' exact element={<ManufacturerView />} />
      <Route path='/fabricante/nova' exact element={<ManufacturerForm />} />
      <Route path='/fabricante/editar' exact element={<ManufacturerForm />} />

      <Route path='/modelos' exact element={<ModelsIndex />} />
      <Route path='/modelo/' exact element={<ModelsView />} />
      <Route path='/modelo/novo' exact element={<ModelsForm />} />
      <Route path='/modelo/editar' exact element={<ModelsForm />} />

      <Route path='/anos' exact element={<YearsIndex />} />
      <Route path='/ano/' exact element={<YearsView />} />
      <Route path='/ano/novo' exact element={<YearsForm />} />
      <Route path='/ano/editar' exact element={<YearsForm />} />

      <Route path='/motores' exact element={<MotorsIndex />} />
      <Route path='/motor/' exact element={<MotorsView />} />
      <Route path='/motor/novo' exact element={<MotorsForm />} />
      <Route path='/motor/editar' exact element={<MotorsForm />} />

      <Route path='/stages' exact element={<StagesIndex />} />
      <Route path='/stage' exact element={<StagesView />} />
      <Route path='/stage/adicional' exact element={<AdditionalStagesView />} />
      <Route path='/stage/novo' exact element={<StagesForm />} />
      <Route path='/stage/editar' exact element={<StagesForm />} />
      <Route path='/stage/clone' exact element={<StagesForm />} />

      <Route path='/stage/tipos' exact element={<StagesTypesIndex />} />
      <Route path='/stage/tipo' exact element={<StagesTypesView />} />
      <Route path='/stage/tipo/novo' exact element={<StagesTypesForm />} />
      <Route path='/stage/tipo/editar' exact element={<StagesTypesForm />} />

      <Route path='/adicionais' exact element={<AdditionalsIndex />} />
      <Route path='/adicional' exact element={<AdditionalsView />} />
      <Route path='/adicional/novo' exact element={<AdditionalsForm />} />
      <Route path='/adicional/editar' exact element={<AdditionalsForm />} />

      <Route path='/novidades' exact element={<PostsIndex />} />
      <Route path='/novidade' exact element={<PostsView />} />
      <Route path='/novidade/nova' exact element={<PostsForm />} />
      <Route path='/novidade/editar' exact element={<PostsForm />} />

      <Route path='/representantes' exact element={<RepresentativesIndex />} />
      <Route path='/representante' exact element={<RepresentativesView />} />
      <Route
        path='/representante/novo'
        exact
        element={<RepresentativesForm />}
      />
      <Route
        path='/representante/editar'
        exact
        element={<RepresentativesForm />}
      />

      <Route
        path='/download/categorias'
        exact
        element={<DownloadsCategoryIndex />}
      />
      <Route
        path='/download/categoria'
        exact
        element={<DownloadsCategoryView />}
      />
      <Route
        path='/download/categoria/nova'
        exact
        element={<DownloadsCategoryForm />}
      />
      <Route
        path='/download/categoria/editar'
        exact
        element={<DownloadsCategoryForm />}
      />

      <Route path='/configuracoes' exact element={<ConfigurationsView />} />
      <Route
        path='/configuracoes/editar'
        exact
        element={<ConfigurationsForm />}
      />

      <Route path='/administradores' exact element={<AdministratorsIndex />} />
      <Route path='/administrador' exact element={<AdministratorsView />} />
      <Route
        path='/administrador/novo'
        exact
        element={<AdministratorsForm />}
      />
      <Route
        path='/administrador/editar'
        exact
        element={<AdministratorsForm />}
      />

      <Route path='/downloads' exact element={<DownloadsIndex />} />
      <Route path='/download' exact element={<DownloadsView />} />
      <Route path='/download/novo' exact element={<DownloadsForm />} />
      <Route path='/download/editar' exact element={<DownloadsForm />} />

      <Route path='/combustiveis' exact element={<FuelsIndex />} />
      <Route path='/combustivel/' exact element={<FuelsView />} />
      <Route path='/combustivel/novo' exact element={<FuelsForm />} />
      <Route path='/combustivel/editar' exact element={<FuelsForm />} />

      <Route path='/equipamentos' exact element={<ReadingsIndex />} />
      <Route path='/equipamento/' exact element={<ReadingsView />} />
      <Route path='/equipamento/novo' exact element={<ReadingsForm />} />
      <Route path='/equipamento/editar' exact element={<ReadingsForm />} />

      <Route path='/pedidos' exact element={<RequestIndex />} />
      <Route path='/pedido/novo' exact element={<RequestForm />} />
      <Route path='/pedido/todos' exact element={<RequestAll />} />
      <Route path='/pedido/:protocol' exact element={<RequestView />} />
      <Route path='/pedido/:protocol/chat' exact element={<RequestChat />} />

      <Route path='/financeiro' exact element={<BillIndex />} />
      {/* <Route path="/fatura/novo" exact element={<BillForm />} /> */}
      <Route path='/financeiro/todos' exact element={<BillAll />} />
      <Route path='/fatura/:_id' exact element={<BillView />} />

      <Route element={<AuthLayout admin />}>
        <Route path='/admin/dealers' exact element={<DealersIndex />} />
      </Route>
      <Route path='/admin/dealer' exact element={<DealersView />} />
      <Route path='/admin/dealer/novo' exact element={<DealersForm />} />
      <Route path='/admin/dealer/editar' exact element={<DealersForm />} />

      <Route element={<AuthLayout admin />}>
        <Route
          path='/admin/dealer/usuarios'
          exact
          element={<DealersUsersIndex />}
        />
      </Route>
      <Route
        path='/admin/dealer/usuario'
        exact
        element={<DealersUsersView />}
      />
      <Route
        path='/admin/dealer/usuario/novo'
        exact
        element={<DealersUsersForm />}
      />
      <Route
        path='/admin/dealer/usuario/editar'
        exact
        element={<DealersUsersForm />}
      />

      <Route path='/descontos' exact element={<DiscountsIndex />} />
      <Route path='/desconto/novo' exact element={<DiscountsForm />} />
      <Route path='/desconto/editar' exact element={<DiscountsForm />} />
    </Route>
  </RRDoutes>
)

export default Routes
