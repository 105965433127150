const sumArrayByKey = (array, key) => {
  return array.reduce((a, b) => a + (b[key] || 0), 0)
}

const sumTransactionsFromBilling = billing => {
  let totalPrice = 0
  for (const i in billing.transactions) {
    for (const h in billing.transactions[i].procedures) {
      totalPrice = totalPrice + billing.transactions[i].procedures[h].price
    }
  }
  return totalPrice
}

const getValidationsErros = err => {
  const errors = {}
  err.inner.forEach(error => {
    errors[error.path] = error.message
  })
  return errors
}

const hourMessage = () => {
  const date = new Date()
  const currentHour = date.getHours()
  if (currentHour >= 3 && currentHour < 12) {
    return 'Bom dia'
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'Boa tarde'
  } else if (currentHour >= 18) {
    return 'Boa noite'
  } else {
    return 'Boa madrugada'
  }
}

const playDing = () => {
  const audio = new window.Audio('/ding.mp3')
  audio.play()
}

const currency = value => {
  return isNaN(value) || value == null
    ? 'R$ -'
    : value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

const currencyByType = (value, type = 'Real') => {
  if (type === 'Dolar') {
    return isNaN(value) || value == null
      ? 'U$ -'
      : value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  } else if (type === 'Euro') {
    return isNaN(value) || value == null
      ? '€ -'
      : value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
  } else if (type === 'Guaraníes') {
    return isNaN(value) || value == null
      ? 'PYG -'
      : value.toLocaleString('es-PY', { style: 'currency', currency: 'PYG' })
  } else if (type === 'Peso colombiano') {
    return isNaN(value) || value == null
      ? 'COP -'
      : value.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
  } else if (type === 'Peso mexicano') {
    return isNaN(value) || value == null
      ? 'MXN -'
      : value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
  } else {
    return isNaN(value) || value == null
      ? 'R$ -'
      : value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }
}

const documentMask = v => {
  v = v.replace(/\D/g, '')

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d)/, '$1.$2')
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    v = v.replace(/^(\d{2})(\d)/, '$1.$2')
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
    v = v.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return v
}

const transliterate = c => {
  return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10
}

const getCheckDigit = vin => {
  const map = '0123456789X'
  const weights = '8765432X098765432'
  let sum = 0
  for (let i = 0; i < 17; ++i) {
    sum += transliterate(vin.charAt(i)) * map.indexOf(weights.charAt(i))
  }
  return map.charAt(sum % 11)
}

const validateChassi = vin => {
  if (vin.length !== 17) return false
  return getCheckDigit(vin) === vin.charAt(8)
}

const validarPlaca = placa => {
  let resposta = 'placa inválida'
  const regexPlaca = /^[a-zA-Z]{3}[0-9]{4}$/
  const regexPlacaMercosulCarro = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/
  const regexPlacaMercosulMoto = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}[0-9]{1}$/
  if (regexPlaca.test(placa)) {
    resposta = 'Placa válida no formato atual'
  }
  if (regexPlacaMercosulCarro.test(placa)) {
    resposta = 'Placa válida (padrão Mercosul - carro)'
  }
  if (regexPlacaMercosulMoto.test(placa)) {
    resposta = 'Placa válida (padrão Mercosul - moto)'
  }
  return resposta
}

export {
  sumArrayByKey,
  validateChassi,
  validarPlaca,
  sumTransactionsFromBilling,
  getValidationsErros,
  documentMask,
  hourMessage,
  currency,
  currencyByType,
  playDing
}
