import React, { useRef, useCallback, useState, useEffect } from 'react'
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Avatar,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  InputBase,
  IconButton,
  Typography
} from '@material-ui/core'
import {
  Paperclip,
  Send,
  Camera as CameraIcon,
  Trash2,
  ExternalLink
} from 'react-feather'
import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import { FileIcon } from 'react-file-icon'

import Body from '../../../components/Body'

import api, { blobAPI } from '../../../services/api'
import socket from '../../../services/socket'
import { useAuth } from '../../../hooks'

import useStyles from './style'

const ChatView = () => {
  const dropRef = useRef(null)
  const bottomChatRef = useRef(null)
  const msgRef = useRef(null)
  const cameraUpload = useRef(null)
  const fileUpload = useRef(null)
  const { user } = useAuth()

  const classes = useStyles()
  const location = useLocation()
  const params = useParams()
  const { protocol } = params

  const [newMessage, setNewMessage] = useState('')
  const [sending, setSending] = useState(false)
  const [filename, setFilename] = useState('')
  const [file, setFile] = useState('')

  const [preview, setPreview] = useState('')
  const [previewIsImage, setPreviewIsImage] = useState('')

  const [blobImagens, setBlobImagens] = useState({})

  const [loadingFile, setLoadingFile] = useState(false)
  const [loadingChat, setLoadingChat] = useState(true)
  const [chat, setChat] = useState([])

  const [drop, setDrop] = useState(false)

  const creatBlob = async fileName => {
    const responseFile = await blobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const openImage = async fileName => {
    setLoadingFile(true)
    const blobURL = await creatBlob(fileName)
    window.open(blobURL)
    setLoadingFile(false)
  }

  const refreshBlobImages = async chat => {
    const finalBlob = blobImagens
    for (const i in chat) {
      if (chat[i].file) {
        if (!finalBlob[i]) {
          if (chat[i].file?.thumbnail) {
            finalBlob[i] = await creatBlob(chat[i].file?.thumbnail)
          }
        }
      }
    }
    setBlobImagens(finalBlob)
  }

  const fetchChat = useCallback(async () => {
    const response = await api.get(`/chat/${protocol}`)
    setChat(response.data.messages)
    refreshBlobImages(response.data.messages)
    setLoadingChat(false)
    setSending(false)
  }, [])

  const handleNewMessage = useCallback(async () => {
    if (!sending) {
      setSending(true)
      // if (!!filename) {
      //   const formData = new FormData()
      //   formData.append('file', file)
      //   formData.append('_id', location.state.os._id)
      //   formData.append('userId', user._id)
      //   formData.append('message', msgRef.current.children[0].children[0].value)
      //   formData.append('from', user.type)

      //   await fileAPI.patch('/os/chat/file', formData)

      //   msgRef.current.children[0].children[0].value = ''
      //   setNewMessage('')
      //   setFilename('')
      //   setFile('')
      //   setPreview('')
      // socket.emit(`chat-${protocol}`, 'newMessage')
      //   setSending(false)
      //   msgRef.current.children[0].children[0].focus()
      //   return
      // }

      if (msgRef.current.children[0].children[0].value) {
        await api.post('/chat', {
          // _id: location.state.os._id,
          protocol,
          userId: user._id,
          message: msgRef.current.children[0].children[0].value,
          from: user.admin ? 'admin' : 'dealer'
        })
        msgRef.current.children[0].children[0].value = ''
        setNewMessage('')
        setFilename('')
        setFile('')
        setPreview('')
        // socket.emit(`chat-${protocol}`, msgRef.current.children[0].children[0].value)
        setSending(false)
        msgRef.current.children[0].children[0].focus()
        return
      }
      setSending(false)
    }
  }, [newMessage, sending, filename, file])

  const handleSelectFile = useCallback(e => {
    const files = e.target.files
    if (files.length > 0) {
      setFilename(files[0].name)
      setFile(files[0])
      handlePreview(files[0])
    }
  }, [])

  const submitHandler = event => {
    event.preventDefault()
    handleNewMessage()
  }

  const handleSocket = useCallback(() => {
    socket.removeAllListeners()

    socket.on(`chat-${protocol}`, async message => {
      window.Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.debug('Notification permission granted.')
        } else {
          console.debug('Unable to get permission to notify.')
        }
      })

      const notification = new window.Notification(
        `Nova mensagem - ${protocol}`,
        {
          body: message,
          icon: 'https://perfortech.prodigymotorbike.com.br/perfortech.png'
        }
      )

      notification.onclick = () => {
        window.focus()
      }

      await fetchChat()
    })
    socket.on(`status-${protocol}`, async () => {
      await fetchChat()
    })
  }, [])

  const handlePreview = file => {
    const preview = new Blob([file], { type: file.type })
    const newPreview = URL.createObjectURL(preview)
    setPreviewIsImage(file.type.split('/')[0] === 'image')
    setPreview(newPreview)
  }

  const handleDropSet = function (e) {
    let l
    let i
    const files = e.target.files || e.dataTransfer.files
    if (l === files.length) {
      for (i = 0; i < l; i++) {
        const file = files[i]
        setFilename(file.name)
        setFile(file)
        handlePreview(file)
      }
    }
  }

  const handleListeners = useCallback(() => {
    window.addEventListener('paste', function (e) {
      const item = Array.from(e.clipboardData.items).find(x =>
        /^image\//.test(x.type)
      )
      if (item) {
        const blob = item.getAsFile()
        setFilename(blob.name)
        setFile(blob)
      }
    })

    const dropZone = dropRef.current
    const showDropZone = () => {
      setDrop(true)
    }
    const hideDropZone = () => {
      setDrop(false)
    }

    const allowDrag = e => {
      e.dataTransfer.dropEffect = 'copy'
      e.preventDefault()
    }

    const handleDrop = e => {
      e.preventDefault()
      hideDropZone()
      handleDropSet(e)
    }

    window.addEventListener('dragenter', function (e) {
      showDropZone()
    })
    dropZone.addEventListener('dragenter', allowDrag)
    dropZone.addEventListener('dragover', allowDrag)
    dropZone.addEventListener('dragleave', function (e) {
      hideDropZone()
    })
    dropZone.addEventListener('drop', handleDrop)
  }, [])

  const scrollToBottom = useCallback(() => {
    if (bottomChatRef.current) {
      bottomChatRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [bottomChatRef])

  useEffect(() => {
    scrollToBottom()
  }, [chat])

  useEffect(() => {
    window.addEventListener('focus', handleSocket(location))
    return () => {
      window.removeEventListener('focus', handleSocket(location))
    }
  }, [handleSocket])

  useEffect(async () => {
    await fetchChat()
    handleSocket()
    handleListeners()
    scrollToBottom()
  }, [])

  return (
    <Body title='OS'>
      <div
        ref={dropRef}
        className={drop ? classes.dropzone : classes.dropzoneI}
      />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Divider />
          {loadingChat ? (
            <LinearProgress color='secondary' />
          ) : (
            <Grid container spacing={2} className={classes.fatherGrid}>
              <Grid item xs={12} className={classes.chat}>
                <List className={classes.scroll}>
                  {chat?.length > 0 ? (
                    <>
                      {chat.map((msg, index) => (
                        <div
                          key={index}
                          className={
                            msg.from === 'dealer' ? classes.left : classes.right
                          }
                        >
                          <ListItem style={{ alignItems: 'flex-end' }}>
                            {msg.from === 'dealer' && (
                              <Avatar
                                alt={msg.name}
                                src='empty.jpg'
                                variant='rounded'
                                className={classes.avatar}
                              />
                            )}
                            <Paper
                              variant='outlined'
                              className={classes.sms}
                              elevation='5'
                            >
                              {msg.file && (
                                <>
                                  {msg.file?.thumbnail ? (
                                    <img
                                      src={blobImagens[index]}
                                      className={classes.thumbnail}
                                      onClick={() => openImage(msg.file?.name)}
                                    />
                                  ) : (
                                    <Button
                                      disabled={loadingFile}
                                      size='small'
                                      endIcon={<ExternalLink size={15} />}
                                      variant='outlined'
                                      onClick={() => openImage(msg.file?.name)}
                                    >
                                      Baixar arquivo
                                    </Button>
                                  )}
                                </>
                              )}
                              <div
                                style={{
                                  display: 'flex',
                                  flexGrow: 1,
                                  flexDirection: 'column'
                                }}
                              >
                                <ListItemText
                                  className={
                                    msg.from !== 'dealer'
                                      ? classes.right
                                      : classes.left
                                  }
                                  primary={
                                    <div
                                      className={
                                        msg.from !== 'dealer'
                                          ? classes.rightText
                                          : classes.leftText
                                      }
                                    >
                                      <Typography
                                        component='span'
                                        variant='subtitle1'
                                        color='textPrimary'
                                      >
                                        {msg.message}
                                      </Typography>
                                    </div>
                                  }
                                />
                                <ListItemText
                                  className={
                                    msg.from !== 'dealer'
                                      ? classes.right
                                      : classes.left
                                  }
                                  style={{ flex: 0, bottom: 0 }}
                                  secondary={
                                    <div
                                      className={
                                        msg.from !== 'dealer'
                                          ? classes.rightText
                                          : classes.leftText
                                      }
                                    >
                                      <Typography
                                        component='span'
                                        variant='caption'
                                        color='textPrimary'
                                        style={{ fontSize: 10 }}
                                      >
                                        {msg.user.name}
                                      </Typography>
                                      <Tooltip
                                        title={moment(msg.date).format(
                                          'DD/MM/YYYY HH:mm'
                                        )}
                                        placement='bottom'
                                      >
                                        <Typography
                                          variant='caption'
                                          style={{ fontSize: 10 }}
                                        >
                                          {' - ' +
                                            moment(msg.date)
                                              .calendar(null, {
                                                sameElse: 'DD/MM/YYYY HH:mm'
                                              })
                                              .toUpperCase()}
                                        </Typography>
                                      </Tooltip>
                                    </div>
                                  }
                                />
                              </div>
                            </Paper>
                            {msg.from !== 'TEC' && (
                              <Avatar
                                alt={msg.name}
                                src='empty.jpg'
                                variant='rounded'
                                className={classes.avatar}
                              />
                            )}
                          </ListItem>
                        </div>
                      ))}
                    </>
                  ) : (
                    <Typography variant='body2'>Nenhuma mensagem.</Typography>
                  )}
                  <div ref={bottomChatRef} />
                </List>
                <Paper
                  variant='outlined'
                  component='form'
                  onSubmit={submitHandler}
                  className={classes.send}
                  elevation='5'
                  w
                >
                  {filename ? (
                    <IconButton
                      disabled={sending}
                      className={classes.iconButton}
                      aria-label='menu'
                      onClick={() => {
                        setFilename('')
                        setFile('')
                        setPreview('')
                      }}
                    >
                      <Trash2 />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        disabled={sending}
                        className={classes.iconButton}
                        aria-label='menu'
                        onClick={() => cameraUpload.current.click()}
                      >
                        <CameraIcon size={17} />
                        <InputBase
                          inputProps={{
                            ref: cameraUpload,
                            type: 'file',
                            accept: 'image/*',
                            capture: 'camera'
                          }}
                          style={{ display: 'none' }}
                          onChange={handleSelectFile}
                        />
                      </IconButton>
                      <IconButton
                        disabled={sending}
                        className={classes.iconButton}
                        aria-label='menu'
                        onClick={() => fileUpload.current.click()}
                      >
                        <Paperclip size={17} />
                        <TextField
                          inputProps={{ ref: fileUpload, type: 'file' }}
                          style={{ display: 'none' }}
                          onChange={handleSelectFile}
                        />
                      </IconButton>
                    </>
                  )}
                  <TextField
                    className={classes.input}
                    placeholder='Digite sua mensagem...'
                    disabled={sending}
                    inputProps={{ 'aria-label': 'Digite sua mensagem' }}
                    ref={msgRef}
                    helperText={
                      filename && `** Arquivo selecionado: ${filename}`
                    }
                  />
                  {preview &&
                    (previewIsImage ? (
                      <img src={preview} className={classes.preview} />
                    ) : (
                      <div className={classes.fileIcon}>
                        <FileIcon extension={filename.split('.')[1]} />
                      </div>
                    ))}
                  <IconButton
                    disabled={sending}
                    className={classes.iconButton}
                    aria-label='directions'
                    onClick={handleNewMessage}
                  >
                    <Send />
                  </IconButton>
                </Paper>
                {sending && <LinearProgress color='secondary' />}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Body>
  )
}

export default ChatView
