import React, { useCallback, useRef, useState, useEffect } from 'react'
import {
  Grid,
  Paper,
  Button,
  Typography,
  LinearProgress,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Input from '../../../components/Input'
import Body from '../../../components/Body'

import { getValidationsErros } from '../../../services/atom'
import api from '../../../services/api'

import useStyles from './style'

const PostsForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const { _id, requestProtocol } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [log, setLog] = useState(false)
  const [loading, setLoading] = useState(false)

  const [fuel, setFuel] = useState('')
  const [fuels, setFuels] = useState([])
  const [files, setFiles] = useState(false)
  // const [publicLog, setPublicLog] = useState('privado')

  const handleFile = useCallback(files => {
    setFiles(files)
  }, [])

  const handleNew = useCallback(
    async data => {
      try {
        if (log) {
          // UPDATE
          formRef.current?.setErrors({})
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório.')
          })
          await schema.validate(data, { abortEarly: false })

          const response = await api.put(`log/${_id}`, {
            name: data.name,
            notes: data.notes,
            youtubeLink: data.youtubeLink,
            // publicLog: publicLog === 'publico',
            requestProtocol: requestProtocol || null,
            publicLog: false,
            fuelId: fuel
          })

          if (response.data.status === 'error') {
            throw new Error(response.data.message)
          }

          if (files.length > 0) {
            for (const file of files) {
              const formData = new FormData()
              formData.append('file', file)
              await api.patch(`/log/${response.data._id}/file`, formData)
            }
          }

          navigate(`/log/${response.data._id}`)
        } else {
          // CREATE
          formRef.current?.setErrors({})
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório.')
          })
          await schema.validate(data, { abortEarly: false })

          const response = await api.post('/log', {
            name: data.name,
            notes: data.notes,
            youtubeLink: data.youtubeLink,
            // publicLog: publicLog === 'publico',
            requestProtocol: requestProtocol || null,
            publicLog: false,
            fuelId: fuel
          })

          if (response.data.status === 'error') {
            throw new Error(response.data.message)
          }

          if (files.length > 0) {
            for (const file of files) {
              const formData = new FormData()
              formData.append('file', file)
              await api.patch(`/log/${response.data._id}/file`, formData)
            }
          }

          if (requestProtocol) {
            navigate(`/pedido/${requestProtocol}`)
            return
          }

          navigate(`/log/${response.data._id}`)
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationsErros(err)
          formRef.current?.setErrors(error)
          return
        }
        enqueueSnackbar(err.message, { variant: 'error' })
      }
    },
    [fuel, files, _id]
  )

  const fetchFuels = useCallback(async () => {
    setLoading(true)
    const response = await api.get('fuels')
    setFuels(response.data)
    if (_id) {
      fetchLogs()
    } else {
      setLoading(false)
    }
  }, [])

  const fetchLogs = useCallback(async () => {
    const response = await api.get(`log/${_id}`)
    setFuel(response.data.fuel)
    setLog(response.data)
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchFuels()
  }, [])

  return (
    <Body title={` ${_id ? 'Editar' : 'Novo'} log `}>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <Typography variant='h5'>
                {_id ? 'Editar' : 'Novo'} log{' '}
                {requestProtocol ? ` do pedido ${requestProtocol}` : ''}
              </Typography>
              <Form
                initialData={log}
                className={classes.form}
                onSubmit={handleNew}
                ref={formRef}
              >
                <Input
                  variant='outlined'
                  margin='normal'
                  id='name'
                  label='Nome *'
                  name='name'
                  autoFocus
                  fullWidth
                />
                <Input
                  variant='outlined'
                  margin='normal'
                  id='youtubeLink'
                  label='Link youtube'
                  name='youtubeLink'
                  fullWidth
                />
                <Input
                  style={{ marginBottom: 15 }}
                  variant='outlined'
                  margin='normal'
                  label='Notas'
                  name='notes'
                  id='notes'
                  multiline
                  fullWidth
                  rows={4}
                />
                <DropzoneArea
                  onChange={files => handleFile(files)}
                  dropzoneText='Arraste arquivo ou clique'
                  acceptedFiles={['text/csv']}
                  maxFileSize={666000000}
                  showAlerts={false}
                  filesLimit={10}
                />
                <Typography variant='h6' style={{ marginTop: 15 }}>
                  Combustível em uso *
                </Typography>
                <RadioGroup
                  row
                  value={fuel}
                  onChange={event => setFuel(event.target.value)}
                >
                  {fuels.map((fuel, index) => (
                    <FormControlLabel
                      key={index}
                      value={fuel._id}
                      control={<Radio color='primary' />}
                      label={fuel.name}
                      onChange={() => setFuel(fuel._id)}
                    />
                  ))}
                </RadioGroup>
                {/* <Typography variant='h6' style={{ marginTop: 15 }}>
                      Log público? *
                    </Typography>
                    <RadioGroup row value={publicLog} onChange={(event) => setPublicLog(event.target.value)}>
                      <FormControlLabel value='privado' control={<Radio color='primary' />} label='Privado' />
                      <FormControlLabel value='publico' control={<Radio color='primary' />} label='Público' />
                    </RadioGroup> */}
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary'
                  className={classes.submit}
                >
                  {_id ? 'Atualizar' : 'Criar'} log
                </Button>
              </Form>
              <Typography variant='caption'>
                Items marcados com * são obrigatórios
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Body>
  )
}

export default PostsForm
