import React, { useCallback, useState, useEffect, useRef } from 'react'
import {
  InputAdornment,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  Box,
  Button,
  Typography,
  Tooltip,
  Menu,
  MenuItem
} from '@material-ui/core'
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  Autocomplete
} from '@material-ui/lab'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { useSnackbar } from 'notistack'

import { Info } from 'react-feather'
import moment from 'moment'

import LogsTable from '../../../components/LogsTable'
import FileTable from '../../../components/FileTable'
import TimeIcon from '../../../components/TimeIcon'
import Body from '../../../components/Body'

import api, { blobAPI, fileAPI } from '../../../services/api'
import statusType from '../../../services/statusType'
import { currency } from '../../../services/atom'
import socket from '../../../services/socket'

import { useAuth } from '../../../hooks'

import useStyles from './style'

const PedidoView = () => {
  const optionsRef = useRef(null)
  const fileUpload = useRef(null)

  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const { user } = useAuth()

  const { enqueueSnackbar } = useSnackbar()

  const [optionOpen, setOptionOpen] = useState(false)

  const [admins, setAdmins] = useState([])
  const [admin, setAdmin] = useState()

  const [request, setRequest] = useState([])
  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState(true)

  const [openValueModal, setOpenValueModal] = useState(false)
  const [value, setValue] = useState('')

  const [openModal, setOpenModal] = useState(false)
  const [sending, setSending] = useState(false)
  const [obs, setObs] = useState('')

  const sendRetune = useCallback(
    async e => {
      const files = e.target.files
      if (files.length > 0) {
        setSending(true)
        const formData = new FormData()
        formData.append('description', 'RETUNE')
        for (let i = 0; i < files.length; i++) {
          formData.append('file', files[i])
        }
        await fileAPI.patch(`/request/${request._id}/new`, formData)
        setOptionOpen(false)
        setSending(false)
        await fetchRequest()
      }
    },
    [request]
  )

  const fetchRequest = useCallback(async () => {
    setLoading(true)
    const { protocol } = params
    const response = await api.get(`/request/${protocol}`)
    setRequest(response.data)
    fetchLogs()
    setLoading(false)
  }, [])

  const fetchLogs = useCallback(async () => {
    setLoading(true)
    const { protocol } = params
    const response = await api.get(`/logs/${protocol}/request`)
    setLogs(response.data)
    setLoading(false)
  }, [])

  const handleOpenModal = useCallback(async () => {
    if (sending) {
      return
    }

    const response = await api.get('/admins')
    setAdmins(response.data)

    setOpenModal(true)
  }, [sending])

  const handleOpenValueModal = useCallback(async () => {
    if (sending) {
      return
    }
    setOpenValueModal(true)
  }, [sending])

  const handleAssignet = useCallback(async () => {
    setSending(true)
    try {
      await api.patch(`/request/assigned/${request._id}`, {
        assignedTo: admin._id
      })
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
      setOpenModal(false)
      setSending(false)
    }
    await fetchRequest()
    setOpenModal(false)
    setSending(false)
    setOptionOpen(false)
  }, [admin])

  const handleNewValue = useCallback(async () => {
    setSending(true)
    try {
      if (value === '') {
        throw new Error('Preencha o valor')
      }
      if (obs === '') {
        throw new Error('Preencha a observação')
      }
      await api.patch(`/request/value/${request._id}`, {
        finalValue: value,
        valueObservation: obs
      })
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
      setOpenValueModal(false)
      setSending(false)
    }
    await fetchRequest()
    setOpenValueModal(false)
    setSending(false)
    setOptionOpen(false)
  }, [value, obs])

  const handleModalClose = useCallback(async () => {
    setOpenModal(false)
    setSending(false)
    setOptionOpen(false)
  }, [])

  const handleCancel = useCallback(async () => {
    setSending(true)
    const observation = window.prompt(
      'Tem certeza que deseja cancelar o pedido? Digite o motivo:'
    )
    if (observation) {
      await api.post('request/cancel', {
        _id: request._id,
        observation
      })

      await fetchRequest()
      setOpenValueModal(false)
      setOptionOpen(false)
    }
    setSending(false)
  }, [request])

  const acceptCancel = useCallback(async () => {
    setSending(true)
    const confirm = window.confirm('Aceitar cacenlamento?')
    if (confirm) {
      await api.put('request/cancel/accept', { _id: request._id })
      await fetchRequest()
      setOpenValueModal(false)
      setOptionOpen(false)
    }
    setSending(false)
  }, [request])

  const denyCancel = useCallback(async () => {
    setSending(true)
    const confirm = window.confirm('Rejeitar cacenlamento?')
    if (confirm) {
      await api.put('request/cancel/deny', { _id: request._id })
      await fetchRequest()
      setOpenValueModal(false)
      setOptionOpen(false)
    }
    setSending(false)
  }, [request])

  const acceptFin = useCallback(async () => {
    setSending(true)
    const confirmation = window.confirm('Deseja finalizar?')
    if (confirmation) {
      await api.put('request/complete', { _id: request._id })
      await fetchRequest()
      setOpenValueModal(false)
      setOptionOpen(false)
    }
    setSending(false)
  }, [request])

  const denyFin = useCallback(async () => {
    setSending(true)
    const confirm = window.confirm('Rejeitar cacenlamento?')
    if (confirm) {
      await api.put('request/cancel/deny', { _id: request._id })
      await fetchRequest()
      setOpenValueModal(false)
      setOptionOpen(false)
    }
    setSending(false)
  }, [request])

  const handleFinisDealer = useCallback(async () => {
    setSending(true)
    const confirmation = window.confirm('Seu pedido foi finalizado?')
    if (confirmation) {
      await api.post('request/finish', { _id: request._id })

      await fetchRequest()
      setOpenValueModal(false)
      setOptionOpen(false)
    }
    setSending(false)
  }, [request])

  const handleFinish = useCallback(async () => {
    setSending(true)
    const confirmation = window.confirm('Seu pedido foi finalizado?')
    if (confirmation) {
      await api.post('request/complete', { _id: request._id })

      await fetchRequest()
      setOpenValueModal(false)
      setOptionOpen(false)
    }
    setSending(false)
  }, [request])

  const handleModalValueClose = useCallback(async () => {
    setOpenValueModal(false)
    setSending(false)
    setOptionOpen(false)
  }, [])

  const handleChat = useCallback(() => {
    const { protocol } = params
    navigate(`/pedido/${protocol}/chat`)
  }, [])

  useEffect(async () => {
    await fetchRequest()
    socket.on('status', async () => {
      await fetchRequest()
    })
  }, [])

  return (
    <Body title='Pedido'>
      <TextField
        id='file'
        name='file'
        inputProps={{ ref: fileUpload, type: 'file', multiple: true }}
        style={{ display: 'none' }}
        onChange={e => sendRetune(e)}
      />
      <Dialog
        open={openModal}
        aria-labelledby='dialogo-orcamento'
        aria-describedby='dialogo-orcamento'
        onClose={handleModalClose}
        fullWidth
        maxWidth='lg'
      >
        <DialogTitle id='titulo-dialogo-orcamento'>
          Alterar responsável
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            noOptionsText='Nenhuma opção'
            variant='outlined'
            margin='normal'
            fullWidth
            className={classes.select}
            options={admins}
            value={admin}
            getOptionLabel={option => `${option?.name}`}
            renderInput={params => (
              <TextField
                {...params}
                placeholder='Selecione novo responsável *'
                variant='outlined'
              />
            )}
            onChange={(_, newValue) => {
              setAdmin(newValue)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAssignet}
            variant='contained'
            disableElevation
            color='primary'
            size='small'
            disabled={sending || !admin}
          >
            Alterar responsável
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openValueModal}
        aria-labelledby='dialogo-orcamento'
        aria-describedby='dialogo-orcamento'
        onClose={handleModalValueClose}
        fullWidth
        maxWidth='lg'
      >
        <DialogTitle id='titulo-dialogo-orcamento'>Alterar valor</DialogTitle>
        <DialogContent>
          <TextField
            placeholder='Novo valor *'
            onChange={e => {
              setValue(e.target.value)
            }}
            value={value}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>R$</InputAdornment>
              )
            }}
            type='number'
            variant='outlined'
          />
          <TextField
            placeholder='Observação *'
            onChange={e => {
              setObs(e.target.value)
            }}
            className={classes.select}
            value={obs}
            fullWidth
            variant='outlined'
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleNewValue}
            variant='contained'
            disableElevation
            color='primary'
            size='small'
          >
            Alterar valor
          </Button>
        </DialogActions>
      </Dialog>
      {user.admin ? (
        <Menu
          open={optionOpen}
          onClose={() => setOptionOpen(false)}
          anchorEl={optionsRef.current}
        >
          <MenuItem key='assigned' onClick={handleOpenModal} disabled={sending}>
            Assinar há
          </MenuItem>
          <Divider />
          <MenuItem
            key='enviar-logs'
            to={`/log/${request.protocol}/novo`}
            disabled={sending}
            component={Link}
          >
            Enviar logs
          </MenuItem>
          <Divider />
          <MenuItem
            key='enviar-arquivo'
            onClick={() => fileUpload.current.click()}
            disabled={sending}
          >
            {sending ? <CircularProgress /> : 'Enviar remap'}
          </MenuItem>
          <Divider />
          <MenuItem key='finish' onClick={handleFinish} disabled={sending}>
            Finalizar pedido
          </MenuItem>
          <Divider />
          <MenuItem key='cancelar' onClick={handleCancel} disabled={sending}>
            Cancelar pedido
          </MenuItem>

          {!request.finished && request.canceled && !request.complete && (
            <>
              <Divider />
              <MenuItem
                key='cancelar'
                onClick={acceptCancel}
                disabled={sending}
                style={{
                  background: 'green',
                  color: 'white',
                  marginTop: '8px'
                }}
              >
                Aceitar cancelamento
              </MenuItem>
              <MenuItem
                key='cancelar'
                onClick={denyCancel}
                disabled={sending}
                style={{ background: 'orange', color: 'white' }}
              >
                Rejeitar cancelamento
              </MenuItem>
            </>
          )}

          {request.finished && !request.canceled && !request.complete && (
            <>
              <Divider />
              <MenuItem
                key='cancelar'
                onClick={acceptFin}
                disabled={sending}
                style={{
                  background: 'green',
                  color: 'white',
                  marginTop: '8px'
                }}
              >
                Aceitar finalização
              </MenuItem>
              <MenuItem
                key='cancelar'
                onClick={denyFin}
                disabled={sending}
                style={{ background: 'orange', color: 'white' }}
              >
                Rejeitar finalização
              </MenuItem>
            </>
          )}
        </Menu>
      ) : (
        <Menu
          open={optionOpen}
          onClose={() => setOptionOpen(false)}
          anchorEl={optionsRef.current}
        >
          <MenuItem key='finish' onClick={handleFinisDealer} disabled={sending}>
            Finalizar pedido
          </MenuItem>
          <MenuItem key='cancelar' onClick={handleCancel} disabled={sending}>
            Cancelar pedido
          </MenuItem>
        </Menu>
      )}

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            {!loading && (
              <>
                <Grid container direction='row'>
                  <Typography variant='h6'>
                    {`Pedido ${request.protocol} - ${
                      statusType[
                        request.timeline[request.timeline.length - 1]?.name
                      ]
                    }`.toUpperCase()}
                  </Typography>
                  <Box flexGrow={1} />
                  <Button
                    aria-label='menu'
                    onClick={() => {
                      setOptionOpen(true)
                    }}
                    ref={optionsRef}
                    endIcon={<Info size={15} />}
                    variant='contained'
                    color='secondary'
                    style={{ marginRight: 10 }}
                  >
                    Opções
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleChat}
                  >
                    Chat
                  </Button>
                </Grid>
                <Typography variant='body'>
                  {`Responsável - ${
                    request.assignedTo
                      ? request.assignedTo?.name
                      : 'não definido'
                  }`.toUpperCase()}
                </Typography>
                <Typography variant='h6'>
                  VALOR {currency(request.finalValue)}
                  {user.admin && (
                    <Button color='primary' onClick={handleOpenValueModal}>
                      Alterar valor
                    </Button>
                  )}
                </Typography>
                <Typography variant='body'>
                  {request.valueObservation ? request.valueObservation : ''}
                </Typography>
                <List component='nav' aria-label='pedido'>
                  {user.admin && (
                    <>
                      <Divider className={classes.divider} />
                      <ListItem>
                        <ListItemText
                          primary='VALOR STAGE'
                          secondary={currency(request.stagePrice)}
                        />
                        <ListItemText
                          primary='DESCONTO'
                          secondary={
                            request.dealerDiscount
                              ? `${request.dealerDiscount}%`
                              : 'Sem desconto'
                          }
                        />
                        <ListItemText
                          primary='COM DESCONTO'
                          secondary={
                            request.dealerDiscount
                              ? currency(
                                  request.stagePrice -
                                    (request.stagePrice / 100) *
                                      request.dealerDiscount
                                )
                              : currency(request.stagePrice)
                          }
                        />
                      </ListItem>
                    </>
                  )}
                  <Divider className={classes.divider} />
                  <ListItem>
                    {/* <ListItemText primary={'Stage'.toUpperCase()} secondary={`${request.manufacturer?.name?.toUpperCase()} - ${request.model?.name?.toUpperCase()} - ${request.year?.year.toUpperCase()} - ${request.motor?.name?.toUpperCase()} - ${request.stage.name?.toUpperCase()}`} /> */}
                  </ListItem>
                  <Divider className={classes.divider} />
                  <ListItem>
                    <ListItemText
                      primary={'Protocolo'.toUpperCase()}
                      secondary={request.protocol.toUpperCase()}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />
                  <ListItem>
                    <ListItemText
                      primary={'Dealer'.toUpperCase()}
                      secondary={request.dealer?.name.toUpperCase()}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />
                  <ListItem>
                    <ListItemText
                      primary={'Chassi'.toUpperCase()}
                      secondary={request.chassi.toUpperCase()}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />
                  <ListItem>
                    <ListItemText
                      primary={'Placa'.toUpperCase()}
                      secondary={
                        request.plate
                          ? request.plate.toUpperCase()
                          : request.plage
                          ? request.plage.toUpperCase()
                          : ''
                      }
                    />
                  </ListItem>
                  <Divider className={classes.divider} />
                  <ListItem>
                    <ListItemText
                      primary={'Combustível de fabrica'.toUpperCase()}
                      secondary={request.originalFuel?.name.toUpperCase()}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={'Combustível em uso'.toUpperCase()}
                      secondary={request.useFuel?.name.toUpperCase()}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />
                  <ListItem>
                    <ListItemText
                      secondary={`Criado ${moment(request?.createdAt)
                        .calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' })
                        .toUpperCase()}`.toUpperCase()}
                    />
                  </ListItem>
                </List>
              </>
            )}
          </Paper>

          {request.additionals?.length > 0 && (
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <Typography variant='h6'>ADICIONAIS</Typography>
              {request.additionals.map((add, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={'Adicional'.toUpperCase()}
                    secondary={add?.name.toUpperCase()}
                  />
                  <ListItemText
                    primary={'Descrição'.toUpperCase()}
                    secondary={add?.description.toUpperCase()}
                  />
                  <ListItemText
                    primary={'Valor'.toUpperCase()}
                    secondary={currency(add?.price)}
                  />
                </ListItem>
              ))}
            </Paper>
          )}

          {request.originalFiles?.length > 0 && (
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <Typography variant='h6'>ARQUIVOS ORIGINAIS</Typography>
              <FileTable
                files={request.originalFiles}
                request={request}
                type='original'
              />
            </Paper>
          )}

          {request.newFiles?.length > 0 && (
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <Typography variant='h6'>ARQUIVOS DE REMAP</Typography>
              <FileTable
                files={request.newFiles}
                request={request}
                type='remap'
              />
            </Paper>
          )}

          {logs.length > 0 && (
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <Typography variant='h6'>LOGS</Typography>
              <LogsTable logs={logs} />
            </Paper>
          )}

          {request?.timeline?.length > 0 && (
            <Paper variant='outlined' className={classes.paper} elevation={0}>
              <Typography variant='h6'>LINHA DO TEMPO</Typography>
              <Timeline>
                {request?.timeline?.map((state, index) => (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent sx={{ py: '12px', px: 2 }}>
                      <Tooltip
                        title={moment(state.createdAt).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      >
                        <Typography variant='caption'>
                          {moment(state.createdAt)
                            .calendar(null, { sameElse: 'DD/MM/YYYY HH:mm' })
                            .toUpperCase()
                            .toUpperCase()}
                        </Typography>
                      </Tooltip>
                    </TimelineOppositeContent>

                    <TimelineSeparator>
                      <TimelineDot color='primary'>
                        <TimeIcon status={state.type} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>

                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                      <Typography variant='body1'>
                        {statusType[state?.name].toUpperCase()}
                      </Typography>
                      {state.observation && (
                        <Typography variant='caption'>
                          OBS: {state.observation.toUpperCase()}
                        </Typography>
                      )}
                      <Typography variant='subtitle2'>
                        {state.user?.name.toUpperCase()}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Body>
  )
}

export default PedidoView
