import React, { useMemo } from 'react'
import {
  Typography,
  Table as MaUTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy
} from 'react-table'
import { ArrowDown, ArrowUp, Download } from 'react-feather'
import moment from 'moment'

import api, { blobAPI } from '../../services/api'

const FileTable = ({ files, request, type = 'original' }) => {
  const openFile = async file => {
    await api.post('/file/download', {
      _id: file._id,
      type,
      request: request._id
    })
    const blobURL = await creatBlob(file?.name)
    window.open(blobURL)
  }

  const creatBlob = async fileName => {
    const responseFile = await blobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)
    return blobURL
  }

  const columns = useMemo(
    () => [
      {
        Header: 'DATA',
        accessor: originalRow =>
          `${moment(originalRow?.createdAt).calendar(null, {
            sameElse: 'DD/MM/YYYY'
          })}`.toUpperCase()
      },
      {
        Header: 'NOME',
        accessor: originalRow => originalRow?.originalName?.toUpperCase()
      },
      {
        Header: 'DESCRIÇÃO',
        accessor: originalRow => originalRow?.description?.toUpperCase()
      }
    ],
    []
  )

  const defaultColumn = useMemo(() => ({ Filter: false }), [])
  const { getTableProps, headerGroups, prepareRow, rows } = useTable(
    { columns, data: files, defaultColumn },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'download',
          Cell: ({ row }) => (
            <IconButton
              onClick={() => openFile(row.original, 'original')}
              size='small'
            >
              <Download />
            </IconButton>
          )
        },
        ...columns
      ])
    }
  )

  return (
    <>
      {files.length > 0 ? (
        <MaUTable {...getTableProps()} style={{ marginTop: 0 }}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDown size={15} />
                      ) : (
                        <ArrowUp size={15} />
                      )
                    ) : (
                      ''
                    )}
                    <>{column.canFilter ? column.render('Filter') : null}</>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <TableRow hover {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => (
                    <TableCell {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </MaUTable>
      ) : (
        <Typography
          variant='h6'
          color='textSecondary'
          style={{ alignSelf: 'center' }}
        >
          {'Nenhum arquivo no momento'.toUpperCase()}
        </Typography>
      )}
    </>
  )
}

export default FileTable
