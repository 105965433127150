import React, { useMemo, useCallback } from 'react'
import {
  Typography,
  Table as MaUTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy
} from 'react-table'
import { ArrowDown, ArrowUp, Search } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import GlobalFilterInput from '../GlobalFilterInput'

const LogsTable = ({ logs }) => {
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: originalRow => originalRow.name.toUpperCase()
      },
      {
        Header: 'Combustível',
        accessor: originalRow => originalRow.fuel?.name?.toUpperCase()
      },
      {
        Header: 'Arquivos',
        accessor: originalRow =>
          `${originalRow.files?.length} arquivo${
            originalRow.files?.length > 1 ? 's' : ''
          }`
      }
    ],
    []
  )

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const defaultColumn = useMemo(() => ({ Filter: false }), [])
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    { columns, data: logs, defaultColumn, filterTypes },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'pedido',
          Cell: ({ row }) => (
            <IconButton
              size='small'
              onClick={() => navigate(`/log/${row?.original?._id}`)}
            >
              <Search />
            </IconButton>
          )
        },
        ...columns
      ])
    }
  )

  return (
    <>
      {logs.length > 0 ? (
        <MaUTable {...getTableProps()} style={{ marginTop: 0 }}>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={visibleColumns.length}
                style={{ textAlign: 'left' }}
              >
                <GlobalFilterInput
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </TableCell>
            </TableRow>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDown size={15} />
                      ) : (
                        <ArrowUp size={15} />
                      )
                    ) : (
                      ''
                    )}
                    <>{column.canFilter ? column.render('Filter') : null}</>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <TableRow key={index} hover {...row.getRowProps()}>
                  {row.cells.map((cell, index) => (
                    <TableCell key={index} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </MaUTable>
      ) : (
        <Typography
          variant='h6'
          color='textSecondary'
          style={{ alignSelf: 'center' }}
        >
          Nenhum log no momento
        </Typography>
      )}
    </>
  )
}

export default LogsTable
