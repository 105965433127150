import React, { useCallback, useState, useEffect } from 'react'
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Tooltip as MUITooltip,
  Typography
} from '@material-ui/core'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { Edit2, Trash2, Eye, EyeOff } from 'react-feather'
import Chart from 'react-apexcharts'

import api, { blobAPI } from '../../../services/api'
import Body from '../../../components/Body'

import useStyles from './style'

const logView = () => {
  const classes = useStyles()
  const { _id } = useParams()
  const navigate = useNavigate()

  const [log, setLog] = useState([])
  const [datas, setDatas] = useState([])
  const [series, setSeries] = useState([])
  const [loading, setLoading] = useState(true)

  const creatBlob = async fileName => {
    const responseFile = await blobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const fetchLog = useCallback(async () => {
    const response = await api.get(`/log/${_id}`)
    setLog(response.data)
    if (response.data?.files?.length > 0) {
      const thisDatas = []
      const thisSeries = {}
      for (let i = 0; i < response.data.files.length; i++) {
        if (response.data.files[i].name.split('.').pop() === 'csv') {
          const name = response.data.files[i].name
          const newFile = await creatBlob(response.data.files[i].name)
          const responseFile = await fetch(newFile)
          const data = await responseFile.arrayBuffer()
          const decoder = new TextDecoder('utf-8')
          const dataString = decoder.decode(data)
          const csv = dataString
          const datasets = []
          const rows = csv.split('\n')
          const labels = rows[0].split(',')
          thisSeries[name] = []
          for (let i = 0; i < labels.length; i++) {
            const label = labels[i]
            const data = []

            for (let j = 3; j < rows.length; j++) {
              if (parseFloat(rows[j].split(',')[i])) {
                data.push(parseFloat(rows[j].split(',')[i]))
              }
            }

            thisSeries[name].push({ name: label, data, hidden: false })
          }
          thisDatas.push({ labels, datasets })
          setDatas([...thisDatas])
          setSeries(thisSeries)
        }
      }
      setLoading(false)
    }
  }, [_id, datas])

  const handlerEdit = useCallback(() => {
    navigate(`/log/${_id}/update`)
  }, [_id])

  const handlerDelete = useCallback(
    async ({ index }) => {
      const confirm = window.confirm('Deseja realmente excluir este log?')
      if (!confirm) return
      await api.delete(`/log/${_id}/file/${index}`)
      await fetchLog()
    },
    [_id]
  )

  useEffect(async () => {
    await fetchLog()
  }, [])

  const options = {
    chart: {
      type: 'line',
      zoom: { enabled: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 10
        },
        dynamicAnimation: {
          enabled: false,
          speed: 10
        }
      }
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: false,
      showForZeroSeries: false,
      position: 'bottom',
      horizontalAlign: 'center'
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    dataLabels: { enabled: false },
    stroke: {
      width: 2,
      curve: 'straight'
    },
    markers: {
      size: 0
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    yaxis: {
      title: { text: 'RPM' },
      labels: {
        formatter: function (val) {
          return parseFloat(val)
        }
      }
    }
  }

  const GraficoIndividual = ({ file, index }) => {
    const toggleSeriesVisibility = e => {
      e.preventDefault()

      setSeries(prevSeries => {
        const seriesArray = JSON.parse(JSON.stringify(prevSeries[file.name]))

        for (const i in seriesArray) {
          seriesArray[i].hidden = !seriesArray[i].hidden
        }

        return {
          ...prevSeries,
          [file.name]: seriesArray
        }
      })
    }

    const allSeriesHidden = () => {
      const seriesArray = series[file.name]
      if (!seriesArray) return false

      return seriesArray.every(s => s.hidden)
    }

    return (
      <Paper key={index} className={classes.chart} elevation={3}>
        <Typography variant='body1'>
          <IconButton
            onClick={toggleSeriesVisibility}
            style={{ marginRight: 5 }}
            aria-label='toggle-log'
            type='button'
            size='small'
          >
            {allSeriesHidden() ? <Eye size={20} /> : <EyeOff size={20} />}
          </IconButton>
          {file.originalName}
          <IconButton
            onClick={() => handlerDelete({ index })}
            className={classes.trash}
            aria-label='remover-log'
            size='small'
          >
            <Trash2 size={20} />
          </IconButton>
        </Typography>
        <Chart
          series={series[file.name]}
          options={options}
          height={555}
          type='line'
        />
      </Paper>
    )
  }

  return (
    <Body title='Log'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                {log.name}
              </Typography>
              <MUITooltip title='EDITAR' arrow placement='right'>
                <IconButton
                  onClick={() => handlerEdit({ log })}
                  className={classes.plus}
                  aria-label='editar-log'
                  size='small'
                >
                  <Edit2 />
                </IconButton>
              </MUITooltip>
              <div style={{ flexGrow: 1 }} />
              {log?.request?.protocol && (
                <Button
                  // onClick={() => handlerEdit({ log })}
                  to={`/pedido/${log?.request?.protocol}`}
                  component={Link}
                  className={classes.plus}
                  aria-label='editar-log'
                  variant='contained'
                  color='primary'
                  size='small'
                >
                  Pedido {log?.request?.protocol}
                </Button>
              )}
            </Grid>
            <List component='nav' aria-label='log'>
              <ListItem>
                <ListItemText
                  style={{ whiteSpace: 'pre' }}
                  secondary={log.notes}
                  primary='NOTAS'
                />
              </ListItem>
              {log.files &&
                log.files.length > 0 &&
                !loading &&
                log.files.map((file, index) => (
                  <GraficoIndividual file={file} index={index} key={index} />
                ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default logView
