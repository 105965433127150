import { makeStyles } from '@material-ui/core'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: { display: 'flex' },
  toolbar: {
    paddingRight: 0,
    paddingLeft: 0
  },
  paper: {
    padding: theme.spacing(1),
    marginLeft: 1
  },
  divider: {
    margin: 5
  },
  toolbarIcon: {
    border: 0,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-end',
    minHeight: '50px'
  },
  toolbarIconCenter: {
    margin: 0,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mixins.toolbar
  },
  appBarSm: {
    background: '#fff',
    color: '#f15b2b',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBar: {
    marginLeft: drawerWidth,
    background: '#fff',
    color: '#f15b2b',
    width: `calc(100% - ${58}px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: { marginLeft: 4 },
  menuButtonHidden: { display: 'none' },
  title: {
    paddingTop: theme.spacing(1),
    flexGrow: 1
  },
  logo: {
    height: 44,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  logoTiny: { height: 30 },
  back: { marginBottom: theme.spacing(2) },
  drawerPaper: {
    background: '#404040',
    // borderRight: '1px solid #f15b2b',
    position: 'relative',
    whiteSpace: 'nowrap',
    padding: 0,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  },
  // theme.mixins.toolbar
  appBarSpacer: { minHeight: '50px' },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper
  },
  fixedHeight: { height: 240 },
  active: {
    color: theme.palette.primary.main,
    '& svg': { color: theme.palette.primary.main }
  }
}))

export default useStyles
